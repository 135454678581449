import React, { useState, useEffect, useContext } from "react"
import { Alert, Container, Row, Col, Form, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link, navigate } from "gatsby"
import { Logout } from "../utils/authentication"
import api from "../utils/api"
import LoadingOverlay from "react-loading-overlay"
import { NavbarContext } from "../context/navbar"
import Seo from "../components/seo"

export default function Login({ location }) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const navContext = useContext(NavbarContext)

  if (typeof window !== `undefined`) {
    window.dataLayer = window.dataLayer || []
  }


  useEffect(() => {

    if (location.search == "?conflict") {
      setError("Account already exists. Please log in through email/password or reset password")
    }
  }, [])

  const goToHome = () => {
    navigate("/")
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const logins = {
      email,
      password,
    }
    if (!email || !password) {
      setIsLoading(false)
      return setError("All fields required.")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setIsLoading(false)
      return setError("Invalid email.")
    } else {
      try {
        const result = await api().post(`/users/login`, { ...logins })

        localStorage.setItem("token", result.data.token)
        localStorage.setItem("user", JSON.stringify(result.data.user))
        localStorage.setItem("userId", result.data.user.id);
        navContext.setUser(JSON.stringify(result.data.user))
        window.dataLayer.push({ event: 'login', userId: JSON.stringify(result.data.user.id) });

        navigate(-1)
      } catch (error) {
        setIsLoading(false)
        const message = error.response.data.message
        setError(message)
      }
    }
  }

  return (
    <LoadingOverlay active={isLoading} spinner text="Logging you in...">
      <Seo
        title="Patameds Login"
        description="Log in to Patameds using your Google or Email address"
        siteUrl="https://www.patameds.com/login"
        canonical="https://www.patameds.com/login"
      ></Seo>
      <Container className="vh-100 d-flex flex-column ">
        <div style={{ marginTop: "100px" }}>
          <Container>
            {error ? <Alert variant="danger">{error}</Alert> : ""}

            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <h1 className="text-center">Login</h1>

                <Form
                  action={process.env.GATSBY_API_URL + "/users/auth/google"}
                >
                  <Button
                    type="submit"
                    style={{ backgroundColor: "white", color: "black", border: "2px solid red" }}
                  >
                    {/* <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />{" "} */}
                    Log in with Google
                  </Button>
                </Form>

                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label style={{ width: "100%" }}>
                      <Row>
                        <Col>Password</Col>
                        <Col className="text-right">
                          <Link to="/request">Forgot Password?</Link>
                        </Col>
                      </Row>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <p
                    css={`
                      text-align: center;
                    `}
                  >
                    Using this platform means you agree to the{" "}
                    <a
                      href="https://www.patameds.com/terms_of_use"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and condition
                    </a>
                  </p>
                  <Row className="mt-4">
                    <Col>
                      <Button variant="secondary" onClick={goToHome}>
                        Cancel
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <Link to="/register">Register</Link>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      <Button
                        variant="primary"
                        type="button"
                        disabled={!email || !password || isLoading}
                        onClick={handleSubmit}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </LoadingOverlay>
  )
}
